// Centra No 2

@font-face {
  font-family: 'centra';
  src: url('/fonts/Centra/CentraNo2-Light.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'centra';
  src: url('/fonts/Centra/CentraNo2-LightItalic.otf') format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: 'centra';
  src: url('/fonts/Centra/CentraNo2-Book.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'centra';
  src: url('/fonts/Centra/CentraNo2-Medium.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'centra';
  src: url('/fonts/Centra/CentraNo2-MediumItalic.otf') format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'centra';
  src: url('/fonts/Centra/CentraNo2-Bold.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

// Playfair Display

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-Regular.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-Italic.ttf') format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-Medium.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-MediumItalic.ttf') format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-SemiBold.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-SemiBoldItalic.ttf') format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-Bold.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-BoldItalic.ttf') format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-ExtraBold.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-ExtraBoldItalic.ttf') format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-Black.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'playfair display';
  src: url('/fonts/Playfair_Display/PlayfairDisplay-BlackItalic.ttf') format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}
